import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Fordham University ": {
      jobTitle: "IT Engineer @",
      duration: "September 2021 - PRESENT",
      desc: [
        "Troubleshot and resolved an average of 10 technical problems per week, ensuring smooth operation and user satisfaction.",
        "Documented technical problems by providing a detailed explanation of the causes of the problem and the solution to the problem.",
        "Setup technologies for new hires through network configuration, edit permission policy and application installation."
      ]
    },
    "Kissell Research Group": {
      jobTitle: "Researcher @",
      duration: "September 2023 - PRESENT",
      desc: [
        "Collaborated with professor Robert Kissell and his team to build a market impact model on the major U.S stock indices.",
        "Coded a web scraper in Python to gather data of major U.S stock indices from Yahoo Finance.",
        "Clean and analyzed the data using Numpy and Pandas to build the I-Star Market Impact Model."
      ]
    },
    "Amazon Web Service": {
      jobTitle: "Software Developer Engineer Intern @",
      duration: "May 2024 - August 2024",
      desc: [ 
        "Led the development of an inventory management system for the helpdesk ticketing system using React, JavaScript, Java, and AWS S3, which reduced missing inventory and bottlenecks in task completion due to missing equipment.",
        "Built a web app using Amazon LLM that helps users cook by providing recipes and ingredient costs from nearby grocers, and included an AI chatbot to answer recipe questions, resulting in a 1st place finish in the Amazon intern hackathon."
      ]
    },
    "CVS": {
      jobTitle: "Store Associate @",
      duration: "July 2021 - September 2021",
      desc: [
        "Operated the cash register efficiently, ensuring accurate transactions and enhancing customer satisfaction.",
        "Managed product restocking and refacing, maintaining organized shelves and improving store presentation."
      ]
    }
    // TDSB: {
    //   jobTitle: "Software Engineer @",
    //   duration: "SEPT 2019 - DEC 2020",
    //   desc: [
    //     "Co-developed homework management software integrable with Google Classroom by utilizing the Python’s Flask micro-framework for the back-end API and Vue.js for the front-end UI, in order to translate business requirements into a functional full-stack application."
    //   ]
    // },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
