import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ExternalLinks from "./ExternalLinks";


class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const projects = {
      "Corp Ai": {
        desc:
          "Machine learning model that would give farmer crop recommendations based on humanity, soil nutrients and temperature, so that the farmer can efficiently utilized their resources.",
        techStack: "Python, Streamlit, Google Cloud Vertex Ai",
        link: "https://github.com/isayahc/Crop-AI",
      },
      "VCT Chatbot": {
        desc:
          "A chatbot that help user assemble a Valorant pro team using AWS LLM RAG to a knowledge base.",
        techStack: "Python, Streamlit, AWS S3, AWS Bedrock",
        link: "https://github.com/JJC3321/VCT",
      },
      "Minecraft Server": {
        desc:
          "Hosted a Minecraft server on AWS EC2 and use AWS VPC to prevent unwanted user entering into the server.",
        techStack: "AWS EC2, AWS VPC", 
      },
      "Stock Terminal": {
        desc:
          "A terminal that would give user stock information based on the stock ticker.",
        techStack: "JavaScript, Python, FastAPI, React, Chart.js",
        link: "https://github.com/JJC3321/stock-terminal",
      },
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/projects</span>
        </div>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`} key={key}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <div className="project-links">
                      {projects[key].link && (
                        <a href={projects[key].link} target="_blank" rel="noopener noreferrer">
                          <GitHubIcon style={{ fontSize: 30 }} />
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
